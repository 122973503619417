import React from 'react';
import { Link } from 'react-router-dom';
import './../styles/Sidebar.css'; // Ensure this file contains styles for the sidebar
// import { hasPermission } from '../../components/helper';

import { hasPermission } from './helper';

const Sidebar = ({ teamInfo }) => {
    // `teamInfo` should contain team data like logo, name, etc.
    // Example structure:
    // { logo: 'logo_url', name: 'Team Name', id: 'teamId' }

    return (
        <div className="sidebar">
            <div className="sidebar-container">
                {/* Team Info Section */}
                <div className="team-info">
                    <img src={teamInfo.logo} alt={`${teamInfo.name} Logo`} className="team-logo" />
                    <h2 className="team-name">{teamInfo.name}</h2>
                </div>

                {/* Navigation Section */}
                <nav className="sidebar-navigation">
                    {/* Dashboard Section */}
                    <div className="sidebar-section">
                        <h3><Link to={`/team/${teamInfo.name}/dashboard`}>Dashboard</Link></h3>

                        {/* <h3>Dashboard</h3> */}
                        <ul>
                            <li><Link to={`/team/${teamInfo.name}/dashboard/staff`}>Staff</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/dashboard/roles`}>Roles</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/dashboard/evaluation`}>Evaluation</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/dashboard/other`}>Other</Link></li>
                        </ul>
                    </div>


                    {hasPermission("TEAM_OWNER") && (
                        <div className="sidebar-section">
                            <h3>Manage</h3>
                            <ul>
                                <li><Link to={`/team/${teamInfo.name}/manage/settings`}>Settings</Link></li>
                                <li><Link to={`/team/${teamInfo.name}/manage/other`}>Other</Link></li>
                            </ul>
                        </div>
                    )}


                    {/* Discord Bot Section */}
                    <div className="sidebar-section">
                        <h3>Discord Bot</h3>
                        <ul>
                            <li><Link to={`/team/${teamInfo.name}/bot/settings`}>Settings</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/bot/ticket`}>Ticket</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/bot/ticket/transcripts`}>Ticket Transcripts</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/bot/other`}>Other</Link></li>
                        </ul>
                    </div>

                    <div className="sidebar-section">
                        <h3>Moderation</h3>
                        <ul>
                            <li><Link to={`/team/${teamInfo.name}/moderation/settings`}>Settings</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/moderation/logs`}>Logs</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/moderation/other`}>Other</Link></li>
                        </ul>
                    </div>

                    {/* Applications Section */}
                    <div className="sidebar-section">
                        <h3>Applications</h3>
                        <ul>
                            <li><Link to={`/team/${teamInfo.name}/applications/open`}>Open Applications</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/applications/closed`}>Closed Applications</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/applications/other`}>Other</Link></li>
                        </ul>
                    </div>

                    {/* Polls Section */}
                    <div className="sidebar-section">
                        <h3>Polls</h3>
                        <ul>
                            <li><Link to={`/team/${teamInfo.name}/polls/open`}>Open Polls</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/polls/create`}>Create Poll</Link></li>
                            <li><Link to={`/team/${teamInfo.name}/polls/other`}>Other</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div >
    );
};

export default Sidebar;
