import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DiscordProfile = ({ accessToken, profile }) => {
    const [discordProfile, setDiscordProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProfile = async (accessToken) => {
        // console.log(token);
        try {
            console.log(accessToken);
            const response = await axios.get('https://discord.com/api/v10/users/@me', {
                headers: { Authorization: `Bearer ${accessToken}` }
            });
            setDiscordProfile(response.data);
        } catch (err) {
            console.error('Error fetching Discord profile:', err);
            if (err.response && err.response.status === 401) {
                console.log('Access token expired, trying to refresh...');
                // Access token expired, try to refresh it
                try {
                    console.log('Refreshing token...');
                    console.log(profile);
                    console.log(profile.userId);
                    const userId = profile.userId;
                    const refreshResponse = await axios.post(`${process.env.REACT_APP_PUBLIC_IP}/api/auth/discord/refresh-token`, { userId });
                    console.log(refreshResponse.data);
                    const newAccessToken = refreshResponse.data.accessToken;
                    // Retry fetching the profile with the new access token
                    fetchProfile(newAccessToken);
                } catch (refreshError) {
                    setError(refreshError);
                }
            } else {
                setError(err);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile(accessToken);
    }, [accessToken]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading profile</div>;

    return (
        <div className="discord-profile">
            <img src={`https://cdn.discordapp.com/avatars/${discordProfile.id}/${discordProfile.avatar}.png`} alt={`${discordProfile.username}'s avatar`} className="avatar" />
            <div className="user-info">
                <h2>{discordProfile.username}</h2>
            </div>
        </div>
    );
};

export default DiscordProfile;