import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../styles/style.css';
import './../styles/profile.css';
import Header from '../components/header';
import Footer from '../components/footer';
import DiscordProfile from '../components/Connections/discordProfile';

const Profile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [discordAccessToken, setDiscordAccessToken] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const PUBLIC_IP = process.env.REACT_APP_PUBLIC_IP;

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get(`${PUBLIC_IP}/api/users/profile`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUserInfo(response.data);

                if (response.data.discordConnected) {
                    setDiscordAccessToken(response.data.discordConnected.accessToken);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, [token]);

    const handleDiscordConnect = () => {
        const token = localStorage.getItem('token'); // Retrieve the JWT token from local storage
        const redirectUri = `${PUBLIC_IP}/api/auth/discord/login?token=${token}`; // Include the token as a query parameter
        window.location.href = redirectUri;
    };

    const handleDiscordUnlink = async () => {
        try {
            await axios.post(`${PUBLIC_IP}/api/auth/discord/unlink`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDiscordAccessToken(null);
            setUserInfo(prevState => ({
                ...prevState,
                discordConnected: false
            }));
        } catch (error) {
            console.error('Error unlinking Discord:', error);
        }
    };

    return (
        <>
            <Header />
            <div className="container profile-container">
                <h2 className="page-title">Profile</h2>
                {userInfo ? (
                    <div className="profile-info">
                        <p><strong>Username:</strong> {userInfo.username}</p>
                        <p><strong>Email:</strong> {userInfo.email}</p>
                        <p><strong>Teams:</strong> {userInfo.teams.join(', ')}</p>
                        <div className="discord-connect">
                            {discordAccessToken ? (
                                <>
                                    <DiscordProfile accessToken={discordAccessToken} profile={userInfo} />
                                    <button className="btn-primary" onClick={handleDiscordUnlink}>
                                        Unlink Discord
                                    </button>
                                </>
                            ) : (
                                <button className="btn-primary" onClick={handleDiscordConnect}>
                                    Connect Discord
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Loading profile...</p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Profile;